"use client";
import * as React from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import { cn } from "../../utils";

export interface SwitchProps {
  label?: string;
  amount?: number;
  insertAsHTML?: boolean;
  labelClassName?: string;
}

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & SwitchProps
>(({ className, label, labelClassName = "", ...props }, ref) => (
  <label className="flex items-center gap-2">
    <SwitchPrimitives.Root
      className={cn(
        "peer inline-flex h-4 w-8 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-[#1BB334] data-[state=unchecked]:border data-[state=unchecked]:border-slate-300 data-[state=unchecked]:pl-[1px]",
        className
      )}
      {...props}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          "pointer-events-none block h-3 w-3 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0 data-[state=unchecked]:bg-slate-300"
        )}
      />
    </SwitchPrimitives.Root>

    {label && (
      <span className={`text-sm cursor-pointer ${labelClassName}`}>
        {label}
      </span>
    )}
  </label>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
