import type {
  ProductParams,
  MinimalSlice,
  MinimalSliceRule,
} from "api-v2/products/types";
import { getClientProducts, getClientSlices } from "api-v2/products";
import { useForm } from "react-hook-form";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
} from "../atoms/form";
import { Checkbox } from "../atoms/checkbox";
import { useEffect, useRef, useState } from "react";
import { Button } from "../atoms/button";
import { Icon } from "../atoms/icon/icon";
import {
  cn,
  getFiltersFromHash,
  sanitizeFilter,
  setHashFilters,
  toUppercase,
} from "../../utils";
import { Switch } from "../atoms/switch";
import { roundToStep, Slider } from "../atoms/slider";
import { Input } from "../atoms/input";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
} from "../atoms/select";
import { useDebounceCallback, useMediaQuery } from "usehooks-ts";
import { isServer } from "api/utils";
import { BapAd } from "../atoms/bap-ad";

interface ProductFiltersProps {
  initialSlices: MinimalSlice[];
  initialProductAmount: number;
  initialFilters?: Record<string, string[]>;
  maxPrice?: number;
  minPrice?: number;
  properties: Record<string, string>;
}

const adjustMaxPrice = (maxPrice: number): number => {
  const hardMaxLimit = 6000;

  if (maxPrice > hardMaxLimit) return hardMaxLimit;

  return roundToStep(maxPrice);
};

const adjustMinPrice = (minPrice: number): number => {
  if (minPrice === 0) return 1;
  if (minPrice <= 10) return minPrice;

  return Math.floor(minPrice / 10) * 10;
};

const ProductFilters = ({
  initialSlices,
  initialProductAmount,
  initialFilters = {},
  maxPrice = 0,
  minPrice = 6000,
  properties,
}: ProductFiltersProps) => {
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const hasClientRendered = useRef(false);
  const [slices, setSlices] = useState<MinimalSlice[]>(initialSlices);
  const [showFilters, setShowFilters] = useState(false);
  const [showFilterButton, setShowFilterButton] = useState(false);
  const [brandSearch, setBrandSearch] = useState("");
  const [expandedRules, setExpandedRules] = useState<Record<string, boolean>>(
    {}
  );
  const [expandedSlices, setExpandedSlices] = useState<Record<string, boolean>>(
    () => {
      const multiRuleSlices = initialSlices.filter(
        (slice) => slice.rules.length > 1
      );

      return multiRuleSlices
        .slice(0, 3)
        .reduce(
          (acc, slice) => ({ ...acc, [slice.key]: true }),
          {} as Record<string, boolean>
        );
    }
  );

  const form = useForm<Record<string, MinimalSliceRule[]>>({
    defaultValues: {
      ...initialSlices.reduce(
        (acc, slice) => ({ ...acc, [slice.key]: [] }),
        {}
      ),
    },
  });

  const debouncedSubmit = useDebounceCallback(() => handleSubmit(), 300);

  useEffect(() => {
    if (isMobile && hasClientRendered.current) return;

    const subscription = form.watch(() => debouncedSubmit());

    return () => subscription.unsubscribe();
  }, [form.watch]);

  useEffect(() => {
    window.addEventListener("product-filters-toggle", toggleShow);

    return () =>
      window.removeEventListener("product-filters-toggle", toggleShow);
  }, []);

  useEffect(() => {
    const filtersFromHash = getFiltersFromHash();

    Object.entries(filtersFromHash).forEach(([key, value]) => {
      const currentSlice = initialSlices.find((slice) => slice.key === key);
      const activeSliceRules =
        currentSlice?.rules.filter((rule) => value.includes(rule.key)) || [];

      if (currentSlice) {
        form.setValue(key, activeSliceRules);
      } else {
        form.setValue(
          key,
          value.map((value) => ({ amount: 0, key: value, name: value }))
        );
      }
    });
  }, [form.setValue]);

  useEffect(() => {
    if (!isMobile) return;

    const handleScroll = () =>
      setShowFilterButton(document.documentElement.scrollTop > 250);

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isMobile]);

  const toggleSliceExpansion = (key: string) => {
    setExpandedSlices((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const toggleRuleExpansion = (key: string) => {
    setExpandedRules((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const fetchSlices = async () => {
    try {
      const values = getFiltersFromHash();

      const { data: slices } = await getClientSlices({
        ...initialFilters,
        ...values,
      });

      setSlices(slices.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async () => {
    const hashValues = getFiltersFromHash();

    const queryFilters = Object.entries(form.getValues())
      .filter(([_, values]) => values && values.length > 0)
      .reduce((obj: Record<string, string[]>, [key, values]) => {
        obj[key] = values.map((value) => value.key);

        return obj;
      }, {});

    if (hashValues.sorteer) queryFilters.sorteer = hashValues.sorteer;

    setHashFilters({ ...queryFilters });

    if (isMobile && hasClientRendered.current) toggleShow();

    await fetchSlices();
    hasClientRendered.current = true;
  };

  const handleResetSlice = (key: string) => {
    form.setValue(key, []);
  };

  const toggleShow = () => {
    if (!showFilters) {
      document.querySelector("body")?.classList.add("noscroll");
      setShowFilters(true);
    } else {
      document.querySelector("body")?.classList.remove("noscroll");
      setShowFilters(false);
    }
  };

  const getDeliveryValue = () => {
    if (isServer()) return initialFilters.levertijd?.[0];
    return form.getValues("levertijd")?.[0]?.key;
  };

  return (
    <div className="w-full">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className={cn(
            "block fixed top-0 left-0 z-50 bg-background pb-20 h-screen w-screen overflow-y-scroll overflow-x-hidden",
            "lg:top-auto lg:left-auto lg:h-auto lg:overflow-y-auto lg:relative lg:w-auto lg:z-0 lg:overflow-x-visible lg:pb-0",
            {
              "hidden lg:block": !showFilters,
            }
          )}
        >
          <div className="flex p-4 bg-primary-dark text-primary-foreground justify-between text-lg lg:hidden">
            Filter {initialProductAmount} producten{" "}
            <Button
              type="button"
              variant="ghost"
              size="icon"
              className="w-6 h-6"
              onClick={toggleShow}
            >
              <Icon icon="close" size={16} />
            </Button>
          </div>
          <FormField
            control={form.control}
            name="prijs"
            render={({ field }) => (
              <FormItem className="p-4 border-b lg:px-0">
                <FormLabel className="relative flex items-center mb-0 w-full h-5">
                  Prijs (Euro’s)
                  <div className="flex flex-1 justify-end gap-2">
                    <Button
                      type="button"
                      size="sm"
                      variant="link"
                      className={cn("font-normal text-xs h-6 z-10", {
                        hidden: (field.value as any) === undefined,
                      })}
                      onClick={() => field.onChange(undefined)}
                    >
                      Wis
                    </Button>
                  </div>
                </FormLabel>
                <FormControl>
                  <Slider
                    aria-label="Prijs in euro's"
                    defaultValue={
                      field.value?.map((slice) => Number(slice.key)) || [
                        adjustMinPrice(minPrice),
                        adjustMaxPrice(maxPrice),
                      ]
                    }
                    onValueCommit={(values) => {
                      form.setValue(field.name, [
                        {
                          amount: 0,
                          key: values[0].toString(),
                          name: values[0].toString(),
                        },
                        {
                          amount: 0,
                          key: values[1].toString(),
                          name: values[1].toString(),
                        },
                      ]);
                    }}
                    min={adjustMinPrice(minPrice)}
                    max={adjustMaxPrice(maxPrice)}
                    showInput
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="score"
            render={({ field }) => (
              <FormItem className="p-4 border-b lg:px-0">
                <FormLabel className="relative flex items-center mb-0 w-full h-5">
                  Minimale reviewscore
                  <div className="flex flex-1 justify-end gap-2">
                    <Button
                      type="button"
                      size="sm"
                      variant="link"
                      className={cn("font-normal text-xs h-6 z-10", {
                        hidden: (field.value as any) === undefined,
                      })}
                      onClick={() => field.onChange(undefined)}
                    >
                      Wis
                    </Button>
                  </div>
                </FormLabel>
                <FormControl>
                  <div className="flex flex-col gap-2">
                    <Slider
                      aria-label="Minimale reviewscore"
                      hideLabel={(field.value as any) === undefined}
                      defaultValue={
                        field.value?.map((slice) => Number(slice.key)) || [1]
                      }
                      onValueCommit={(values) => {
                        form.setValue(field.name, [
                          {
                            amount: 0,
                            key: values[0].toString(),
                            name: values[0].toString(),
                          },
                        ]);
                      }}
                      min={1}
                      max={10}
                      step={1}
                    />
                    <div className="flex justify-between text-xs text-input font-bold">
                      <span>1</span>
                      <span>10</span>
                    </div>
                  </div>
                </FormControl>
              </FormItem>
            )}
          />
          {/* <FormField
            control={form.control}
            name="levertijd"
            render={({ field }) => (
              <FormItem className="p-4 border-b lg:px-0">
                <FormLabel className="h-5">Levertijd</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={(value) =>
                      field.onChange([
                        {
                          amount: 0,
                          key: value,
                          name: value,
                        },
                      ])
                    }
                    value={getDeliveryValue()}
                  >
                    <SelectTrigger aria-label="Levertijd">
                      <SelectValue placeholder="Selecteer een optie" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {DELIVERY_OPTIONS.map((option) => (
                          <SelectItem
                            key={option}
                            value={sanitizeFilter(option)}
                          >
                            {option}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          /> */}
          {slices
            .filter(
              (slice) =>
                form.getValues(slice.key).length > 0 ||
                slice.rules.some((rule) => rule.amount > 0)
            )
            .filter((slice) => slice.rules.length > 1)
            .map((slice) => {
              let rules = [...slice.rules];

              const isExpanded = expandedSlices[slice.key] || false;
              const isRulesExpanded = expandedRules[slice.key] || false;
              const isGroupChecked = form.getValues(slice.key).length > 0;

              if (slice.key === "merken")
                rules = rules.filter((rule) =>
                  rule.name.toLowerCase().includes(brandSearch.toLowerCase())
                );

              if (!isGroupChecked) rules = rules;

              const displayedRules = isRulesExpanded
                ? rules
                : rules.slice(0, 5);

              return (
                <FormField
                  key={slice.key}
                  control={form.control}
                  name={slice.key}
                  render={({ field }) => {
                    const showReset = field.value.length > 0;

                    return (
                      <FormItem className="p-4 border-b lg:px-0">
                        <FormLabel className="relative flex items-center mb-0 w-full h-5">
                          {slice.name}

                          <div className="flex flex-1 justify-end gap-2 mr-6">
                            <Button
                              type="button"
                              size="sm"
                              variant="link"
                              className={cn("font-normal text-xs h-6 z-10", {
                                hidden: !showReset,
                              })}
                              onClick={() => handleResetSlice(slice.key)}
                            >
                              Wis
                            </Button>

                            <Button
                              type="button"
                              variant="link"
                              size="icon"
                              onClick={() => toggleSliceExpansion(slice.key)}
                              className="text-foreground absolute left-0 top-0 w-full h-full justify-end"
                            >
                              <Icon
                                icon={
                                  isExpanded
                                    ? "chevronBoldUp"
                                    : "chevronBoldDown"
                                }
                                size={12}
                              />
                            </Button>
                          </div>
                        </FormLabel>
                        <FormControl>
                          <div
                            className={cn("flex flex-col gap-2", {
                              hidden: !isExpanded,
                            })}
                          >
                            {slice.key === "merken" && (
                              <Input
                                type="search"
                                value={brandSearch}
                                onChange={(e) => setBrandSearch(e.target.value)}
                                placeholder="Merk zoeken..."
                              />
                            )}

                            {displayedRules.map((rule) => {
                              const fieldValues = [...field.value];

                              return (
                                <label
                                  key={JSON.stringify(rule)}
                                  className="flex items-center gap-2"
                                >
                                  <Checkbox
                                    label={toUppercase(rule.name)}
                                    checked={fieldValues.some(
                                      (value) => value.key === rule.key
                                    )}
                                    id={rule.key}
                                    amount={
                                      !isGroupChecked ? rule.amount : undefined
                                    }
                                    onCheckedChange={(checked) => {
                                      const newValue = checked
                                        ? [...fieldValues, rule]
                                        : fieldValues.filter(
                                            (v) => v.key !== rule.key
                                          );

                                      field.onChange(newValue);
                                    }}
                                  />
                                </label>
                              );
                            })}

                            {rules.length > 5 && (
                              <Button
                                type="button"
                                variant="link"
                                className="text-left justify-start px-0 h-4 text-xs"
                                onClick={() => toggleRuleExpansion(slice.key)}
                              >
                                <Icon
                                  icon={isRulesExpanded ? "minus" : "plus"}
                                  size={8}
                                />
                                {isRulesExpanded ? "Toon minder" : "Toon meer"}
                              </Button>
                            )}
                          </div>
                        </FormControl>
                      </FormItem>
                    );
                  }}
                />
              );
            })}
          {slices
            .filter((slice) => slice.rules.length === 1)
            .filter((slice) =>
              slice.key === "aanbieding"
                ? !("minDiscount" in initialFilters)
                : true
            )
            .map((slice) => {
              const rule = slice.rules[0];

              return (
                <FormField
                  control={form.control}
                  name={slice.key}
                  render={({ field }) => {
                    const fieldValues = [...field.value];

                    return (
                      <FormItem className="py-1.5 px-4 lg:px-0">
                        <FormControl>
                          <Switch
                            label={toUppercase(slice.name)}
                            checked={fieldValues.some(
                              (value) => value.key === rule.key
                            )}
                            onCheckedChange={(checked) => {
                              const newValue = checked
                                ? [...fieldValues, rule]
                                : fieldValues.filter((v) => v.key !== rule.key);

                              field.onChange(newValue);
                            }}
                          />
                        </FormControl>
                      </FormItem>
                    );
                  }}
                />
              );
            })}

          <div className="mt-4 mx-4">
            <BapAd variant="fluid" properties={properties} mobile />
          </div>

          <div className="fixed bottom-0 left-0 z-30 bg-background shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)] w-full p-3 lg:hidden">
            <Button type="button" size="lg" fullWidth onClick={handleSubmit}>
              Filters toepassen
            </Button>
          </div>
        </form>
      </Form>

      <Button
        variant="primaryDark"
        size="lg"
        className={cn(
          "hidden fixed left-1/2 bottom-8 -translate-x-1/2 px-4 z-30",
          { "flex lg:hidden": showFilterButton }
        )}
        onClick={toggleShow}
      >
        Producten filteren <Icon icon="filter" size={12} />
      </Button>
    </div>
  );
};

export { ProductFilters };
