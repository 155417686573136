"use client";

import * as React from "react";
import * as SliderPrimitive from "@radix-ui/react-slider";
import { cn } from "../../utils";
import { Input } from "./input";

interface SliderProps {
  showInput?: boolean;
  hideLabel?: boolean;
}

export const getStepSize = (value: number): number => {
  if (value < 10) return 1;
  if (value < 100) return 10;
  if (value < 500) return 50;
  if (value < 2000) return 100;

  return 500;
};

export const roundToStep = (value: number) => {
  const step = getStepSize(value);
  return Math.round(value / step) * step;
};

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & SliderProps
>(
  (
    {
      className,
      defaultValue,
      min = 0,
      max = 6000,
      step,
      showInput = false,
      hideLabel = false,
      onValueCommit,
      ...props
    },
    ref
  ) => {
    const [currentValue, setCurrentValue] = React.useState<number[]>(
      defaultValue || []
    );
    const [showLabel, setShowLabel] = React.useState(!hideLabel);

    React.useEffect(() => {
      if (!defaultValue) return;

      setCurrentValue(defaultValue);
    }, [defaultValue]);

    React.useEffect(() => {
      setShowLabel(!hideLabel);
    }, [hideLabel]);

    const handleValueChange = (value: string, index: number) => {
      const newValue = [...currentValue];
      newValue[index] = Number(value);

      setShowLabel(true);
      setCurrentValue(newValue);
    };

    const handleSliderChange = (values: number[]) => {
      setShowLabel(true);

      const roundedValues = values.map(roundToStep);
      setCurrentValue(roundedValues);
    };

    const handleInputCommit = (value: string, index: number) => {
      if (!onValueCommit) return;

      let newValue = Number(value);

      if (isNaN(newValue) || value === "") {
        newValue = index === 0 ? min : max;
      }

      newValue = roundToStep(newValue);

      const updatedValues = [...currentValue];
      updatedValues[index] = newValue;

      setCurrentValue(updatedValues);
      onValueCommit(updatedValues);
    };

    return (
      <div className="flex flex-col gap-2">
        <SliderPrimitive.Root
          ref={ref}
          className={cn(
            "relative flex w-full touch-none select-none items-center pt-6",
            className
          )}
          value={currentValue}
          min={roundToStep(min || 1)}
          max={roundToStep(max || 6000)}
          step={step}
          onValueChange={handleSliderChange}
          onValueCommit={onValueCommit}
          defaultValue={defaultValue}
          {...props}
        >
          <SliderPrimitive.Track className="relative h-0.5 w-full grow overflow-hidden rounded-full bg-input cursor-pointer">
            <SliderPrimitive.Range className="absolute h-full bg-primary" />
          </SliderPrimitive.Track>

          {currentValue.map((val, index) => {
            const align =
              currentValue.length > 1
                ? index === 0
                  ? "start"
                  : "end"
                : "center";

            return (
              <SliderPrimitive.Thumb asChild>
                <div className="relative" aria-label="verander waarde">
                  {showLabel && (
                    <div
                      className={cn(
                        "absolute -top-full -translate-y-1/2 bg-primary-dark text-primary-foreground py-0 px-2 h-5 rounded flex items-center justify-center font-bold text-xs z-20",
                        {
                          "left-1/2 -translate-x-1/2":
                            align === "center" && val !== min && val !== max,
                          "left-0": val === min || align === "start",
                          "right-0": val === max || align === "end",
                        }
                      )}
                    >
                      {val}
                    </div>
                  )}
                  <div className="block h-4 w-4 rounded-full border-2 border-primary bg-background cursor-pointer ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"></div>
                </div>
              </SliderPrimitive.Thumb>
            );
          })}
        </SliderPrimitive.Root>

        {showInput && (
          <div className="flex gap-2">
            {currentValue.map((value, index) => (
              <Input
                aria-label={`waarde ${index + 1}`}
                type="number"
                value={value === 0 ? "" : value}
                onChange={(e) => handleValueChange(e.target.value, index)}
                onBlur={(e) => handleInputCommit(e.target.value, index)}
                min={min}
                max={max}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
);
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
